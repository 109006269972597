import { graphql, navigate } from 'gatsby';
import { ALLOW_INSURED_BIRDI_PRICE } from 'gatsby-env-variables';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { sortBy } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import sanitizeHtml from 'sanitize-html';

// UI Kit & Components
import CircleInfo from 'ui-kit/icons/info/circle-info-icon';
import PageSection from 'ui-kit/page-section/page-section';
import Spinner from 'ui-kit/spinner/spinner';

import { AutoRefillLearnMoreModal } from 'components/auto-refill-learn-more-modal';
import AutoRefillOtherInfo from 'components/auto-refill-other-info/auto-refill-other-info.component';
import { AutoRefillTermsAndConditionsModal } from 'components/auto-refill-terms-and-conditions-modal';
import { BirdiModalHeaderDanger } from 'components/birdi-modal/birdi-modal-header';
import InternalHeader from 'components/internal-header/internal-header.component';
import MarketingPageLayout from 'components/layouts/page/marketingpage.layout';
import PrescriptionTabs from 'components/prescriptions-list-v2/prescription-tabs/prescription-tabs.component';
import { autoRefillPrescriptionPayloadToProps } from 'components/prescriptions-list-v2/prescriptions-card/auto-refill-payload-to-props';
import AutoRefillPrescriptionCardV2 from 'components/prescriptions-list-v2/prescriptions-card/auto-refill-prescription-card.component';
import { EasyRefillPrescriptionCardProps } from 'components/prescriptions-list-v2/prescriptions-card/types';

// Auto Refill
import {
    autoRefillGetPatientAdressesRoutine,
    autoRefillGetPatientDataRoutine,
    autoRefillGetPatientFamilyDataRoutine,
    autoRefillGetPatientPaymentCardsRoutine,
    autoRefillToggleAutoRefillRxStatusRoutine
} from 'state/auto-refill/auto-refill.reducer';
import {
    autoExpiredSelector,
    autoRefillAcCodeSelector,
    autoRefillAccountHasInsuranceSelector,
    autoRefillAccountIsCaliforniaUserSelector,
    autoRefillAdressesSelector,
    autoRefillEligibleRxsSelector,
    autoRefillePostPatientNumberSelector,
    autoRefillFamilyDataSelector,
    autoRefillFamilyRxSelector,
    autoRefillFirstNameSelector,
    autoRefillFlagsSelector,
    autoRefillIsCaregiverSelector,
    autoRefillLoadingFamilyDataSelector,
    autoRefillLoadingRxsSelector,
    autoRefillRxsLoadedSelector,
    autoRefillToggleRxBusySelector,
    autoRefillUserBearerTokenSelector,
    isAutoRefillPlanOnSelector
} from 'state/auto-refill/auto-refill.selectors';
// Modals
import { closeModal, openModal, setBusyModal } from 'state/birdi-modal/birdi-modal.reducers';
import { drugDiscountPriceRoutine } from 'state/drug/drug.routines';

import { AutoRefillFlag, PatientFamilyDataList, RxResult } from 'types/auto-refill';
import { EasyRefillRxResult } from 'types/easy-refill';

import { validateAutoRefillTermsAndConditionsModalDisplay } from 'util/autorefill';
import { lowercaseAndCapitalize } from 'util/cart';

// Hoc & Types
import withUnauthenticatedSessionExpiration from 'hoc/withUnauthenticatedSessionExpiration';

import { EasyRefillErrorModal } from '../../easy-refill';
import './index.style.scss';
import './index.style.scss';

const AutoRefillPrescriptions = ({
    data
}: {
    data: GatsbyTypes.GetAutoRefillPrescriptionLanguageAndImageDataQuery;
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { blueSkyBackground } = data;

    // --------------------
    // Selectors
    // -------------------
    const isExpired = useSelector(autoExpiredSelector);
    const autoRefillAddresses = useSelector(autoRefillAdressesSelector);
    const firstName: string = useSelector(autoRefillFirstNameSelector);
    const autoRefillLoadingRxs = useSelector(autoRefillLoadingRxsSelector);
    const autoRefillBearerToken = useSelector(autoRefillUserBearerTokenSelector);
    const autoRefillEligibleRxs = useSelector(autoRefillEligibleRxsSelector);
    const autoRefillRxsLoaded = useSelector(autoRefillRxsLoadedSelector);
    const autoRefillBusy = useSelector(autoRefillToggleRxBusySelector);
    const autoRefillFlags = useSelector(autoRefillFlagsSelector);
    const autoRefillAccountHasInsurance = useSelector(autoRefillAccountHasInsuranceSelector);
    const autoRefillePostPatientNumber = useSelector(autoRefillePostPatientNumberSelector);
    const autoRefillIsCaregiver = useSelector(autoRefillIsCaregiverSelector);
    const autoRefillFamilyData = useSelector(autoRefillFamilyDataSelector);
    const isCaliforniaUser = useSelector(autoRefillAccountIsCaliforniaUserSelector);
    const isFamilyHasRxs = useSelector(autoRefillFamilyRxSelector);
    const loadingFamilyData = useSelector(autoRefillLoadingFamilyDataSelector);
    const planAlias = useSelector(autoRefillAcCodeSelector);
    const isAutoRefillPlanOn = useSelector(isAutoRefillPlanOnSelector);

    // --------------------
    // Local state
    // -------------------
    const [orderedRxs, setOrderedRxs] = useState<EasyRefillPrescriptionCardProps[]>([]);
    const [activeDependentTab, setActiveDependentTab] = useState<string | null>(null);
    const [filteredRxs, setFilteredRxs] = useState<EasyRefillPrescriptionCardProps[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    // --------------------
    // Memoized values
    // -------------------

    // Create a variable that returns dependents information
    const dependents = useMemo(() => {
        if (autoRefillFamilyData.length > 0) {
            return autoRefillFamilyData.map((item, key) => ({
                ePostPatientNum: item.epostPatientNum,
                familyMemberName: lowercaseAndCapitalize(`${item.firstName || ''} ${item.lastName || ''}`),
                id: key,
                planAlias: item.planAlias
            }));
        } else {
            return [];
        }
    }, [autoRefillFamilyData]);

    // --------------------
    // Callbacks
    // -------------------

    // Display error modal
    const handleShowErrorModal = useCallback(
        (errorText: string) => {
            dispatch(
                openModal({
                    showClose: true,
                    size: 'lg',
                    type: 'danger',
                    headerContent: (
                        <BirdiModalHeaderDanger headerText={t('modals.easyRefillFailure.title')} icon="alert" />
                    ),
                    bodyContent: <EasyRefillErrorModal translation={t} errorMessage={errorText} />,
                    ctas: [
                        {
                            label: t('modals.autoRefillFailure.buttonText'),
                            variant: 'primary',
                            onClick: () => {
                                dispatch(closeModal({}));
                            },
                            dataGALocation: 'EasyRefillNotVerified'
                        }
                    ]
                })
            );
        },
        [dispatch, t]
    );

    // Function that handles when the user turns auto refill on/off
    // TODO: When refactoring, consider to reuse these functions in
    // medicine cabinet and auto refill flows
    const handleAutoRefillToggle = useCallback(
        (autoRefillRxs: RxResult[], autoRefillEnabled: boolean) => {
            autoRefillRxs.forEach(async (rx) => {
                dispatch(
                    autoRefillToggleAutoRefillRxStatusRoutine.trigger({
                        rxsToToggle: { rxSeqNum: rx.rxSeqNum, newStatus: autoRefillEnabled, rxNumber: rx.rxNumber },
                        onSuccess: () => {
                            dispatch(closeModal({}));
                            if (autoRefillIsCaregiver) {
                                dispatch(
                                    autoRefillGetPatientFamilyDataRoutine.trigger({
                                        idLoadingPrescriptions: false,
                                        onFailure: (error: string) => {
                                            if (error !== undefined) {
                                                handleShowErrorModal(error);
                                            }
                                        }
                                    })
                                );
                                return;
                            }
                            dispatch(
                                autoRefillGetPatientDataRoutine.trigger({
                                    idLoadingPrescriptions: false,
                                    onFailure: (error: string) => {
                                        if (error !== undefined) {
                                            handleShowErrorModal(error);
                                        }
                                    }
                                })
                            );
                        }
                    })
                );
            });
        },
        [dispatch, handleShowErrorModal, autoRefillIsCaregiver]
    );

    // Function that shows terms and conditions modal.
    const handleShowTCModal = useCallback(
        (rxNumbers: RxResult[], autoRefillEnabled: boolean) => {
            dispatch(
                openModal({
                    onClose: () => {
                        dispatch(closeModal({}));
                    },
                    ctas: [
                        {
                            label: t('modals.autoRefillTC.confirm'),
                            variant: 'primary',
                            onClick: () => {
                                dispatch(setBusyModal(true));
                                handleAutoRefillToggle(rxNumbers, autoRefillEnabled);
                            },
                            async: true,
                            dataGALocation: 'UnauthenticatedAutoRefillTCConfirm'
                        },
                        {
                            label: t('modals.autoRefillTC.cancel'),
                            variant: 'text',
                            onClick: () => {
                                dispatch(closeModal({}));
                            },
                            dataGALocation: 'UnauthenticatedAutoRefillTCCancel',
                            className: 'p-4'
                        }
                    ],
                    bodyContent: <AutoRefillTermsAndConditionsModal t={t} />,
                    showClose: true
                })
            );
        },
        [dispatch, handleAutoRefillToggle, t]
    );

    // Function that handles the click of an auto refill toggle to decide
    // whether terms and conditions modal should be displayed
    // TODO: When refactoring, ensure to apply the same logic in between flows
    const handleShowAutoRefillToggleModal = useCallback(
        (rxNumbers: RxResult[], autoRefillEnabled: boolean) => {
            const isAnyRxHasAutoRefill = autoRefillFlags?.some((rx) => rx.autoRefillEnabled);
            const showAutoRefillTermsAndConditions = validateAutoRefillTermsAndConditionsModalDisplay(
                autoRefillEnabled,
                isAnyRxHasAutoRefill,
                isAutoRefillPlanOn,
                isCaliforniaUser
            );

            if (showAutoRefillTermsAndConditions) {
                handleShowTCModal(rxNumbers, autoRefillEnabled);
            } else {
                handleAutoRefillToggle(rxNumbers, autoRefillEnabled);
            }
        },
        [handleAutoRefillToggle, handleShowTCModal, isAutoRefillPlanOn, isCaliforniaUser, autoRefillFlags]
    );

    // For context, the structure of the API response, returns the auto refill
    // properties in a different array, so we have this function to find the results
    // of a certain property gotten.
    const getAutoRefillProperty = useCallback(
        (rxNumber: string, activeDependent: string | null = null, property: string) => {
            const findRx = (autoRefillArr: any) => {
                if (!autoRefillArr) return null;
                return autoRefillArr.find((rx: AutoRefillFlag) => rx.rxNumber === rxNumber);
            };

            if (autoRefillIsCaregiver && activeDependent) {
                const selectedEligibleRxs = autoRefillFamilyData.find(
                    (dependent) => dependent.epostPatientNum === activeDependent
                );
                const foundRx = findRx(selectedEligibleRxs?.autoRefillFlags);
                if (!foundRx) return false;
                return foundRx[property];
            }

            const foundRx = findRx(autoRefillFlags);
            if (!foundRx) return false;
            return foundRx[property];
        },
        [autoRefillFlags, autoRefillIsCaregiver, autoRefillFamilyData]
    );

    // Function that gets if the auto refill toggle is enabled for a prescription
    const rxAutoRefillEnabled = useCallback(
        (rxNumber: string, activeDependent: string | null = null) =>
            getAutoRefillProperty(rxNumber, activeDependent, 'autoRefillEnabled'),
        [getAutoRefillProperty]
    );

    // Function that gets if the Rx is eligible for auto refill for a certain prescription
    const rxAutoRefillEligible = useCallback(
        (rxNumber: string, activeDependent: string | null = null) =>
            getAutoRefillProperty(rxNumber, activeDependent, 'autoRefillEligible'),
        [getAutoRefillProperty]
    );

    // Function that displays the learn more about auto refill modal
    const handleAutoRefillLearnMoreModal = useCallback(() => {
        dispatch(
            openModal({
                showClose: true,
                ctas: [
                    {
                        label: t('modals.autoRefillLearnMore.gotIt'),
                        variant: 'primary',
                        onClick: () => {
                            dispatch(closeModal({}));
                        }
                    }
                ],
                bodyContent: <AutoRefillLearnMoreModal t={t} />
            })
        );
    }, [dispatch, t]);

    // Function that handles a tab change
    // TODO: When refactoring consider another approach to don't need to
    // pass an unused tab property.
    const handleTabItemClick = (tab = 'all', dependent: string | null) => {
        setActiveDependentTab(dependent);
    };

    // Function that renders Rxs for family members.
    const renderFamilyPrescriptions = (
        prescriptions: EasyRefillRxResult[] | null,
        dependent: string | null,
        planAlias: string | null
    ) => {
        if (!prescriptions) return null;

        return prescriptions.length > 0 ? (
            prescriptions.map((prescription, index) => (
                <Col className="mb-4 flex-fill auto-refill-rx-card" key={`rx-${index}`} xs={6} sm={6} md={12}>
                    <AutoRefillPrescriptionCardV2
                        key={`auto-refill-cabinet-rx-card-${prescription.rxNumber}`}
                        {...(prescription as any)}
                        autoRefillBusy={autoRefillBusy}
                        autoRefillEnabledForRx={rxAutoRefillEnabled(prescription.rxNumber, dependent)}
                        autoRefillOnChange={handleShowAutoRefillToggleModal}
                        autoRefillEligibleForRefill={rxAutoRefillEligible(prescription.rxNumber, dependent)}
                        isAccountInsured={autoRefillAccountHasInsurance}
                        isCaliforniaUser={isCaliforniaUser}
                        planAlias={planAlias}
                    />
                </Col>
            ))
        ) : (
            <Col xs={12} md={12} lg={12}>
                <span className="text-dark">{t('pages.autoRefill.prescriptions.noResultsFound')}</span>
            </Col>
        );
    };

    // --------------------
    // Use effect
    // -------------------

    // If the link has expired or there is no bearer token set, redirect to /link-expired page
    useEffect(() => {
        if (isExpired || !autoRefillBearerToken) {
            navigate('/link-expired?flow=auto-refill');
        }
    }, [isExpired, autoRefillBearerToken]);

    // If there is no eligible rxs redirect to /no-prescriptions-eligible
    useEffect(() => {
        if (
            !isLoading &&
            ((autoRefillIsCaregiver && isFamilyHasRxs === false) ||
                (autoRefillEligibleRxs.length === 0 && !autoRefillIsCaregiver))
        ) {
            navigate('/no-prescriptions-eligible?flow=auto-refill');
        }
    }, [isLoading, autoRefillIsCaregiver, isFamilyHasRxs, autoRefillEligibleRxs.length]);

    // Listen loaded values to set loading value as false, allowing the user to interact properly with the UI
    useEffect(() => {
        if (
            (autoRefillIsCaregiver && autoRefillRxsLoaded && !loadingFamilyData) ||
            (!autoRefillIsCaregiver && autoRefillRxsLoaded)
        ) {
            setIsLoading(false);
        }
    }, [autoRefillRxsLoaded, autoRefillIsCaregiver, isFamilyHasRxs, autoRefillEligibleRxs.length, loadingFamilyData]);

    // Get Patient Data Family
    useEffect(() => {
        // Load data if the token hasn't expired
        if (!isExpired) {
            // If the user is a caregiver and data hasn't been loaded
            if (autoRefillIsCaregiver && !autoRefillRxsLoaded) {
                // Setup active tab and get data from API
                setActiveDependentTab(autoRefillePostPatientNumber);
                dispatch(
                    autoRefillGetPatientFamilyDataRoutine.trigger({
                        onFailure: (error: string) => {
                            if (error !== undefined) {
                                handleShowErrorModal(error);
                            }
                        }
                    })
                );
                dispatch(
                    autoRefillGetPatientDataRoutine.trigger({
                        onFailure: (error: string) => {
                            if (error !== undefined) {
                                handleShowErrorModal(error);
                            }
                        }
                    })
                );
            } else {
                // If user is not caregiver and data hasn't been loaded
                if (!autoRefillRxsLoaded) {
                    // Get the auto refill information from API
                    dispatch(
                        autoRefillGetPatientDataRoutine.trigger({
                            onFailure: (error: string) => {
                                if (error !== undefined) {
                                    handleShowErrorModal(error);
                                }
                            }
                        })
                    );
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [autoRefillRxsLoaded, autoRefillIsCaregiver, isExpired, autoRefillePostPatientNumber]);

    // Load Patient Adresses & Payment Cards
    useEffect(() => {
        // Validate if token is still valid
        if (!isExpired) {
            dispatch(
                autoRefillGetPatientAdressesRoutine.trigger({
                    onFailure: (error: string) => {
                        if (error !== undefined) {
                            handleShowErrorModal(error);
                        }
                    }
                })
            );
            dispatch(
                autoRefillGetPatientPaymentCardsRoutine.trigger({
                    onFailure: (error: string) => {
                        if (error !== undefined) {
                            handleShowErrorModal(error);
                        }
                    }
                })
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isExpired]);

    // Processing properties for every eligible auto refill Rx
    // TODO: When refactoring, consider to fix type issues.
    useEffect(() => {
        const prescriptionProps: EasyRefillPrescriptionCardProps[] = autoRefillEligibleRxs.map((prescription) =>
            autoRefillPrescriptionPayloadToProps(prescription as EasyRefillRxResult, t, autoRefillAccountHasInsurance)
        );
        const orderedPrescriptionProps = sortBy(prescriptionProps, ['sortOrder', 'prescriptionName']);

        setOrderedRxs(orderedPrescriptionProps);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [autoRefillEligibleRxs]);

    // Get discount drug prices after the profile and all
    // prescriptions are loaded.
    useEffect(() => {
        if (
            // If filtered rxs are loaded or orderedRxs are loaded
            (filteredRxs.length > 0 || orderedRxs.length > 0) &&
            // and if is caregiver and we have loaded the dependents
            // or if is not caregiver but we have already a plan alias
            ((autoRefillIsCaregiver && dependents.length > 0) || (!autoRefillIsCaregiver && planAlias))
        ) {
            // Find the Prescriptions that should show price based on
            // corresponding object being rendered for each case (filtered or ordered)
            // TODO: When refactoring, we need to standardize and get values from a same
            // variable to simplify logic and enhance performance.
            const priceEligiblePrescriptions = (autoRefillIsCaregiver ? filteredRxs : orderedRxs).filter(
                (prescription) => prescription.showPrice
            );

            // If we have prescriptions and we have loaded the addresses
            if (priceEligiblePrescriptions.length > 0 && autoRefillAddresses.length > 0) {
                // If there are Rxs to get price for, we trigger corresponding APIs
                dispatch(
                    drugDiscountPriceRoutine.trigger({
                        prescriptions: priceEligiblePrescriptions.map((prescription) => prescription.fullPayload),
                        forceBirdiInsurance: !!(autoRefillAccountHasInsurance && ALLOW_INSURED_BIRDI_PRICE),
                        location: 'MedCabinet',
                        unAuthArea: 'auto-refill'
                    })
                );
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderedRxs, filteredRxs, autoRefillAddresses]);

    // Use effect that handles the filtering of eligible rxs for auto refill
    useEffect(() => {
        if (autoRefillFamilyData.length > 0 && activeDependentTab) {
            const selectedEligibleRxs: PatientFamilyDataList | undefined = autoRefillFamilyData.find(
                (dependent) => dependent.epostPatientNum === activeDependentTab
            );

            if (!selectedEligibleRxs) return;

            const hasEligibleRxs = selectedEligibleRxs.autoRefillFlags
                .filter((rx: any) => rx.autoRefillEligible)
                .map((rx: any) => rx.rxNumber);

            if (hasEligibleRxs.length === 0) {
                setFilteredRxs([]);
                return;
            }

            const eligibleFilteredRxs: RxResult[] = selectedEligibleRxs?.rxResults.filter((rx) =>
                hasEligibleRxs.includes(rx.rxNumber)
            );

            const prescriptionProps: EasyRefillPrescriptionCardProps[] = eligibleFilteredRxs.map((prescription) =>
                autoRefillPrescriptionPayloadToProps(prescription as any, t, autoRefillAccountHasInsurance)
            );

            const sortedOrders: EasyRefillPrescriptionCardProps[] = sortBy(prescriptionProps, [
                'sortOrder',
                'prescriptionName'
            ]);

            setFilteredRxs(sortedOrders);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeDependentTab, autoRefillFamilyData]);

    return (
        <MarketingPageLayout
            headerImage={blueSkyBackground}
            headerImageClassName="profile-background"
            metaData={{ nodeTitle: 'Auto Refill' }}
            suppressApplicationPage={true}
            pageContentClassName="auto-refill-prescription-page"
        >
            <PageSection>
                <Container fluid className="auto-refill-prescriptions">
                    <InternalHeader
                        sectionIndex={0}
                        title={t('pages.autoRefill.prescriptions.headlineText')}
                        eyebrowText={t('pages.autoRefill.prescriptions.eyebrowText')}
                        body={
                            isLoading ? (
                                <Spinner isVisible={isLoading} t={t} />
                            ) : (
                                <>
                                    <p>{t('pages.autoRefill.prescriptions.greeting', { firstName })} </p>
                                    <button
                                        onClick={handleAutoRefillLearnMoreModal}
                                        className="auto-refill-greeting-learn-more-link"
                                        role="link"
                                    >
                                        {t('pages.autoRefill.prescriptions.learnMoreLink')}
                                    </button>
                                </>
                            )
                        }
                    />
                    <Row className={`${isLoading ? 'd-none' : 'd-flex'}`}>
                        {autoRefillIsCaregiver && (
                            <>
                                <Col xs={12} md={12} lg={8}>
                                    <Row>
                                        <Col xs={12} md={12} lg={12}>
                                            {autoRefillFamilyData.length > 0 && (
                                                <PrescriptionTabs
                                                    activeTab={'all'}
                                                    activeDependentTab={
                                                        activeDependentTab || autoRefillePostPatientNumber
                                                    }
                                                    isPrescriptionsAvailable={false}
                                                    onTabItemChange={handleTabItemClick}
                                                    dependents={dependents}
                                                    myEpostPatientNum={autoRefillePostPatientNumber}
                                                    hasFilterTabs={false}
                                                    hasAutoRefillFlag={false}
                                                />
                                            )}
                                        </Col>
                                        {!autoRefillLoadingRxs &&
                                            !isExpired &&
                                            renderFamilyPrescriptions(filteredRxs, activeDependentTab, planAlias)}
                                    </Row>
                                </Col>
                            </>
                        )}
                        {!autoRefillIsCaregiver && (
                            <Col xs={12} md={12} lg={8}>
                                <Row>
                                    {!autoRefillLoadingRxs && !isExpired && (
                                        <>
                                            {autoRefillEligibleRxs.length > 0 ? (
                                                <>
                                                    {orderedRxs.map((prescription, index) => (
                                                        <Col
                                                            className="mb-4 flex-fill auto-refill-rx-card"
                                                            key={`rx-${index}`}
                                                            xs={6}
                                                            sm={6}
                                                            md={12}
                                                        >
                                                            <AutoRefillPrescriptionCardV2
                                                                key={`auto-refill-cabinet-rx-card-${prescription.rxNumber}`}
                                                                {...prescription}
                                                                autoRefillBusy={autoRefillBusy}
                                                                autoRefillEnabledForRx={rxAutoRefillEnabled(
                                                                    prescription.rxNumber
                                                                )}
                                                                autoRefillOnChange={handleShowAutoRefillToggleModal}
                                                                autoRefillEligibleForRefill={rxAutoRefillEligible(
                                                                    prescription.rxNumber
                                                                )}
                                                                isAccountInsured={autoRefillAccountHasInsurance}
                                                                isCaliforniaUser={isCaliforniaUser}
                                                                planAlias={planAlias}
                                                            />
                                                        </Col>
                                                    ))}
                                                </>
                                            ) : (
                                                <Col xs={12} md={12} lg={12}>
                                                    <span className="text-dark">
                                                        {t('pages.autoRefill.prescriptions.noResultsFound')}
                                                    </span>
                                                </Col>
                                            )}
                                        </>
                                    )}
                                </Row>
                            </Col>
                        )}
                        <Col xs={12} md={12} lg={4}>
                            {autoRefillRxsLoaded && (
                                <>
                                    <AutoRefillOtherInfo />
                                    <div className="auto-refill-prescriptions-other-info-message">
                                        <div className="icon">
                                            <CircleInfo variant="secondary" />
                                        </div>
                                        <div>
                                            <p
                                                dangerouslySetInnerHTML={{
                                                    __html: sanitizeHtml(
                                                        t('pages.autoRefill.otherInformation.message'),
                                                        {
                                                            allowedTags: ['strong']
                                                        }
                                                    )
                                                }}
                                            />
                                            <button
                                                onClick={handleAutoRefillLearnMoreModal}
                                                className="auto-refill-link"
                                                role="link"
                                            >
                                                {t('pages.autoRefill.prescriptions.learnMoreLink')}
                                            </button>
                                        </div>
                                    </div>
                                </>
                            )}
                        </Col>
                    </Row>
                </Container>
            </PageSection>
        </MarketingPageLayout>
    );
};

export default withUnauthenticatedSessionExpiration(AutoRefillPrescriptions, 'auto-refill');

export const query = graphql`
    query GetAutoRefillPrescriptionLanguageAndImageData($language: String) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        blueSkyBackground: file(relativePath: { eq: "assets/images/white-feathers-background-02.png" }) {
            id
            childImageSharp {
                fluid {
                    # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
                    ...GatsbyImageSharpFluid
                }
                gatsbyImageData(formats: [AUTO])
            }
        }
    }
`;
